import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  template: `
    <div class="app-container">
      <!-- <app-header>
        <app-navigation></app-navigation>
      </app-header> -->
      <app-header></app-header>
      <!-- <app-user-info></app-user-info> -->
      <main>
        <router-outlet></router-outlet>
      </main>
      <app-pc-footer></app-pc-footer>
      <app-mobile-footer-navigation></app-mobile-footer-navigation>
    </div>
  `,
  styles: [
    `
      .app-container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
      }
      main {
        flex: 1;
        overflow-y: auto;
        padding-bottom: 60px; // モバイルフッターの高さ分のパディング
      }
      app-pc-footer {
        display: none;
      }
      app-mobile-footer-navigation {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60px;
        z-index: 20;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0.5rem 0;
        background-color: #2d3748;
      }
      // パソコンの表示
      @media (min-width: 769px) {
        main {
          padding-bottom: 0; // PCの場合はパディングを削除
        }
        app-pc-footer {
          display: block;
        }
        app-mobile-footer-navigation {
          // display: none;
        }
      }
    `,
  ],
})
export class AppComponent {
  title = environment.service_name;
  // title = 'jfaa-competition_communication-using-video_frontend-angular16';
}
