// 本番環境でデバッグする場合の設定
export const environment = {
  production: true,
  useMockData: false,
  debug: true,
  // デバッグ用の追加設定
  enableVerboseLogging: true,
  enableSwipeDebug: true,
  enableHlsDebug: true,
  preloadCount: 3,
  service_name: 'VtoV',
  topDir: '/',
  apiRoot: 'https://vtov-backend.sofny4.work/api', // APIのエンドポイントの設定
  // apiRoot: 'https://compe.sofny4.work/backend/api', // APIのエンドポイントの設定
  baseUrl: 'http://vtov.sofny4.work',
  competition_theme: 'cost-cutting',
  maxImageUploadLimit: 10, // コンペに登録できる画像ファイルの個数の上限
  stripePublicKey: 'pk_test_mfLZB9u96dJUggcPT0T7HST700PdhwFcJm',
  competitionManagementPath:
    'mypage/competition-management/holding-competitions',
  maxSelectableSchedules: 1, // 面談日時の最大選択可能数
  public_hls_player_api_url:
    'https://vtov-backend.sofny4.work/api/public/read/read-public-hls-video.php',
  auth_hls_player_api_url:
    'https://vtov-backend.sofny4.work/api/videos/authenticated/read/auth-read-get-hls-by-video-id.php',
  organizer_hls_player_api_url:
    'https://vtov-backend.sofny4.work/api/videos/authenticated/read/auth-read-organizer-get-bidder-hls-video.php',
  api_url_bidder_play_hls_video:
    'https://vtov-backend.sofny4.work/api/videos/authenticated/read/auth-read-organizer-get-bidder-hls-video.php',
  VISIBLE_VIDEOS: 7,
};
