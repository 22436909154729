import { Component } from '@angular/core';
import { AuthService, UserInfo } from '../../auth/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  template: `
    <header>
      <ng-container *ngIf="loggedInUserInfo$ | async as userInfo">
        <div class="proxy-login-wrapper" *ngIf="userInfo.is_proxy_login">
          <!-- <div class="proxy-login-wrapper" *ngIf="true"> -->
          <div class="marquee-container">
            <div class="marquee-content">
              <div class="proxy-login-text">
                <div>代理ログイン中です。</div>
                <div>作業終了後、本部側で代理ログインを解除してください。</div>
                <div>
                  <span class="company-name"
                    >会員ID: {{ userInfo.member_id }}</span
                  >
                  <span>会員名: {{ userInfo.member_name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="member-login-wrapper none" *ngIf="!userInfo.is_proxy_login">
          <div>会員ログイン中です。</div>
          <div>
            <div class="company-name">会員ID: {{ userInfo.member_id }}</div>
            <div>会員名: {{ userInfo.member_name }}</div>
          </div>
        </div>
      </ng-container>
    </header>
  `,
  styles: [
    `
      @use 'variables' as variables;
      :host {
        z-index: 21;
      }
      header {
        width: 100%;
        background-color: #fff; // または必要な背景色
      }
      .proxy-login-wrapper {
        padding: 5px;
        background-color: #ffe6e6;
        color: #ff0000;
        overflow: hidden;
      }
      .member-login-wrapper {
        padding: 5px;
        background-color: #e6ffe6;
        color: #ff0000;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .marquee-container {
        width: 100%;
        overflow: hidden;
      }

      .marquee-content {
        display: flex;
        // animation: marquee 5s linear infinite;
        // padding: 5px 0;
      }

      .proxy-login-text {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        // gap: 5px; // 要素間の間隔を追加
      }

      .proxy-login-text > div {
        // margin: 2px 0;
      }

      @keyframes marquee {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-100%);
        }
      }

      .none {
        display: none;
      }
    `,
  ],
})
export class HeaderComponent {
  loggedInUserInfo$: Observable<UserInfo | null> =
    this.authService.loggedInUserInfo$;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    // サービス側のログイン状態を取得するメソッドを実行(サービス内の会員情報loggedInUserInfo$も更新される)
    this.authService.checkAuthenticationStatus().subscribe();
  }
}
